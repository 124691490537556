import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { getShowTextToSpeech } from '../utils/localStorage.js';
import { answerBoardEmitter } from '../modules/answerBoardEmitter.js';
import AnswerBoardInlineForm from './AnswerBoardInlineForm.js';
import AnswerBoardCategorySearch from './AnswerBoardCategorySearch.js';
import AnswerBoardSymptomSearch from './AnswerBoardSymptomSearch.js';
import AnswerBoardStaticForm from './AnswerBoardStaticForm.js';
import AnswerBoardDemoForm from './AnswerBoardDemoForm.js';
import AnswerBoardSelectBodyPartForm from './AnswerBoardSelectBodyPartForm.js';
import AnswerBoardWCSTView from './AnswerBoardWCSTView.js';
import {
  Icon
} from 'framework7-react';

// import { __DEMO__RestartDaoZhen } from '../modules/shufu.js';

import { Preloader } from 'framework7-react';

AnswerBoard.defaultProps = {
  message: {}
};
export default function AnswerBoard(props) {
  // const [content, setContent] = useState(null);
  const [content, setContent] = useState();
  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(false);
  const showTextToSpeech = getShowTextToSpeech();
  const [answerBoardStyle, setAnswerBoardStyle] = useState({});
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const answerBoardScrollRef = useRef();

  const maxHeight = showTextToSpeech === 'true' ? { 'max-height': '55vh' } : {};

  const setAnswerBoardHandler = useCallback(
    async _content => {
      setLoading(true);
      console.log('_content', _content);
      try {
        if (_content.type === 'categorySearch') {
          setContent(_content);
        } else if (_content.type === 'inlineFormQuestion') {
          setContent(_content);
        } else if (_content.type === 'empty') {
          setContent({});
        } else if (_content.type === 'symptomSearch') {
          setContent(_content);
        } else if (_content.type === 'staticForm') {
          setContent(_content);
        } else if (_content.type === 'demoChooseItemsAndViewInvoiceForm') {
          setContent(_content.form);
        } else if (_content.type === 'selectBodyPartPictureForm') {
          setContent(_content.form);
        } else if (_content.type === 'selectBodyPartPictureFormV2') {
          setContent(_content.form);
        } else if (_content.type === 'WCSTView') {
          setContent(_content);
        }
        setType(_content.type);

        //  set size
        if (_content.type === 'inlineFormQuestion' || _content.type === 'categorySearch' || _content.type === 'demoChooseItemsAndViewInvoiceForm' || _content.type === 'staticForm' || _content.type === 'selectBodyPartPictureForm'|| _content.type === 'selectBodyPartPictureFormV2' || _content.type === 'WCSTView') {
          setAnswerBoardStyle({
            height: 'unset'
          });
        } else if (_content.type === 'empty') {
          setAnswerBoardStyle({
            height: 0
          });
        } else if (_content.type === 'symptomSearch') {
          setAnswerBoardStyle({
            height: 'unset',
            "maxHeight": '70vh'
          });
        } else {
          setAnswerBoardStyle({
            height: 200
          });
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
      //scroll to the end
      setTimeout(() => {
        var messageListWrapper = document.getElementById(
          'message_list_wrapper'
        );
        if (messageListWrapper) {
          // console.log("scroll to top AnswerBoard after handler: ", messageListWrapper.scrollTop);
          messageListWrapper.scrollTop = messageListWrapper.scrollHeight;
        }
      }, 500);
    },
    [setAnswerBoardStyle]
  );

  const hideAnswerBoardOnSubmit = useCallback(() => {
    setAnswerBoardStyle({
      height: 0
    });
  }, [setAnswerBoardStyle]);

  useEffect(() => {
    const { current } = answerBoardScrollRef;
    if (current) {
      const scrollTo = current.scrollTo ? () => current.scrollTo() : window.scrollTo;
      scrollTo(0, 0); //reset scroll when content changes
    }
  }, [content]);

  useEffect(() => {
    setIsScrolledToBottom(true);
    setTimeout(() => {
      const { current } = answerBoardScrollRef;
      if (!current) {
        console.warn("answerboard scroll not found");
        return;
      }
      if (current.scrollHeight !== current.clientHeight) {
        setIsScrolledToBottom(false);
      } else {
        setIsScrolledToBottom(true);
      }
    }, 500);
  }, [content]);

  useEffect(() => {
    const { current } = answerBoardScrollRef;
    if (!current) {
      console.warn("answerboard2 scroll not found");
      return;
    }
    const scrollListener = (event) => {
      var element = event.target;
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        setIsScrolledToBottom(true);
      } else {
        setIsScrolledToBottom(false);
      }
    };
    current.addEventListener('scroll', scrollListener);
    return () => current.removeEventListener("scroll", scrollListener);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      var messageListWrapper = document.getElementById('message_list_wrapper');
      if (messageListWrapper) {
        console.log("scroll to top AnswerBoard default useEffect: ", messageListWrapper.scrollTop);
        messageListWrapper.scrollTop = messageListWrapper.scrollHeight;
      }
    }, 500);
  }, [type]);

  // answerBoard底部回答栏，需要在别的地方emit这个事件
  useEffect(() => {
    answerBoardEmitter.on('set-answer-board', setAnswerBoardHandler);
    answerBoardEmitter.on('submit', hideAnswerBoardOnSubmit);
    return () => {
      answerBoardEmitter.removeListener(
        'set-answer-board',
        setAnswerBoardHandler
      );
      answerBoardEmitter.removeListener('submit', hideAnswerBoardOnSubmit);
    };
  }, [setAnswerBoardHandler, hideAnswerBoardOnSubmit]);


  const child = useMemo(() => {
    switch (type) {
      case 'categorySearch':
        return (
          <AnswerBoardCategorySearch
            category={content.category}
            avSchemaIdSuffix={content.avSchemaIdSuffix}
            subcategories={content.subcategories}
            title={content.title}
            buttonText={content.buttonText}
            defaultSymptomsTags={content.defaultSymptomsTags}
            removeCategoryPage={content.removeCategoryPage}
            limit={content.limit}
            key={Date().now}
          ></AnswerBoardCategorySearch>
        );
      case 'inlineFormQuestion':
        return (
          <div className="answer-board-inline-form-wrapper">
            <AnswerBoardInlineForm submitProps={content.submitProps} question={content.question} key={Date().now} />
          </div>
        );
      case 'symptomSearch':
        return (
          <div className="answer-board-inline-form-wrapper">
            <AnswerBoardSymptomSearch
              content={content}
              key={Date().now}
            ></AnswerBoardSymptomSearch>
          </div>
        );
      case 'staticForm':
        return (
          <div className="answer-board-inline-form-wrapper">
            <AnswerBoardStaticForm jumpToStaticForm={props.jumpToStaticForm} content={content} key={Date().now} />
          </div>
        );
      case 'empty':
        return null;
      case 'demoChooseItemsAndViewInvoiceForm':
        return (
          <div className="answer-board-inline-form-wrapper">
            <AnswerBoardDemoForm
              content={content}
              key={Date().now}
            ></AnswerBoardDemoForm>
          </div>
        );
      case 'selectBodyPartPictureForm':
        return (
          <div className="answer-board-inline-form-wrapper">
            <AnswerBoardSelectBodyPartForm
              content={content}
              key={Date().now}
              bodyType="bodyPart"
            ></AnswerBoardSelectBodyPartForm>
          </div>
        );
      case 'selectBodyPartPictureFormV2':
        return (
          <div className="answer-board-inline-form-wrapper">
            <AnswerBoardSelectBodyPartForm
              content={content}
              key={Date().now}
              bodyType="joint"
            ></AnswerBoardSelectBodyPartForm>
          </div>
        );
      case 'WCSTView':
        return (
          <div className="answer-board-inline-form-wrapper">
            <AnswerBoardWCSTView
              content={content}
              key={Date().now}
            >
            </AnswerBoardWCSTView>
          </div>
        )
      default:
        return null;
    }
  }, [content, props.jumpToStaticForm, type])

  return useMemo(() => {
    const renderDownArrow = () => {
      return !isScrolledToBottom ? <div className="down-arrow" >
        <Icon style={{ "alignSelf": "center" }} f7="chevron_down" color="white" size="30px" />
      </div> : null;
    }

    if (loading) {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            margin: 20,
            justifyContent: 'center'
          }}
        >
          <Preloader size={30} />
        </div>
      );
    }

    return (
      <div ref={answerBoardScrollRef} className="answerBoardContainer" style={{ ...answerBoardStyle, ...maxHeight }}>
        {child}
        {renderDownArrow()}
      </div>
    );
  }, [answerBoardStyle, child, isScrolledToBottom, loading, maxHeight])
}
