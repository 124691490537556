const customConfig = {
    // 登录方式
    loginType: {
      loginTypeTitle: '请选择证件类型并输入',
      options: [
        {
          label: '身份证号',
          value: 'patientIdCard',
          // 卡号长度
          cardLength: 0,
        },
        {
          label: '就诊卡号',
          value: 'patientCard',
          cardLength: 0,
          // 是否需要对卡号进行转换
          needConvert: false,
          // convertCertificateVal: (certificateVal) => {
          //   return '00' + certificateVal
          // }
        },
        {
          label: '住院号',
          value: 'encounterIdentifier',
          cardLength: 0,
          // 是否需要对卡号进行转换
          needConvert: false,
        }
      ]
    }
}
export default customConfig;