import shufu_logo from '../../images/shufu-logo.png';
import shandong_logo from '../../images/shandong-logo.png';
import changning_logo from '../../images/changning-logo.jpg';
import pudong_logo from '../../images/pudong_logo.png';
import szgj_logo from '../../images/suzhouguangji_logo.png';
const uiConfigs = {
  shandong: {
    logo: shandong_logo,
    welcomeText: '山东省精神卫生中心欢迎您！',
    notes: '请患方谨慎填写预问诊信息，提高医生就诊效率。'
  },
  changning: {
    logo: changning_logo,
    welcomeText: '欢迎使用AI预问诊系统',
    notes: '请根据患者真实情况填写，数据将保存在院内系统，系统将保证您的个人信息安全。'
  },
  pdjw: {
    logo: pudong_logo,
    welcomeText: '欢迎使用预问诊',
    notes: '请患方如实填写预问诊信息，您填写的报告将会在医生端显示'
  },
  suzhouguangji: {
    logo: szgj_logo,
    welcomeText: '欢迎使用预问诊',
    notes: '请患方如实填写预问诊信息，您填写的报告将会在医生端显示'
  },
  default: {
    logo: shufu_logo,
    welcomeText: '欢迎',
    notes: '请患方如实填写预问诊内容，您的回答将呈现在医生的工作站上'
  }
}
const pageOrgByEnv = {
  'sdjw': 'shandong',
  'sdjw_uat': 'shandong',
  'sdjw_internal': 'shandong',
  'sdjw_uat_internal': 'shandong',
  'changning': 'changning',
  'pdjw': 'pdjw',
  'suzhouguangji_internal': 'suzhouguangji',
  'suzhouguangji_uat_internal': 'suzhouguangji',
  'suzhouguangji': 'suzhouguangji',
  'suzhouguangji_uat': 'suzhouguangji',
}

export {
  uiConfigs,
  pageOrgByEnv
}