import React, { useState } from 'react';
import {
  Page,
  Navbar,
  NavLeft,
  Link,
  Icon,
  Block,
  // BlockTitle,
  NavTitle,
  List,
  ListItem,
  BlockFooter,
  Button,
  NavRight
} from 'framework7-react';
import 'framework7-icons';
import moment from 'moment';
import { setCurrentUserInfo } from '../utils/localStorage.js';
import addUserIcon from '../images/add.png';
import titleAddUser from '../images/addUser.png';
import userIcon from '../images/avatar.png';
ChooseUserListPopupForSmhc.defaultProps = {
  userList: [],
  onSelectUser: function () {
    console.warn('onSelectUser not defined');
  },
  onCreateNewUser: function () {
    console.warn('onCreateNewUser not defined');
  }
};

/**
 * 精总流程--流程开启后才输入身份证和挂号信息，并且拿不到患者姓名等信息
 * 用户创建时，显示序号，不够明确，
 * 所以修改为如果存在用户，则创建按钮放的隐蔽点
 * 如果没有用户则提示创建用户
 * @param {*} props 
 * @returns 
 */
export default function ChooseUserListPopupForSmhc(props) {
  const [deleteUserFlag, setDeleteUserFlag] = useState(false);
  function renderAddNewUser() {
    return (
      <List mediaList inset>
        <ListItem
          title="新建用户"
          link="#"
          subtitle="创建一个新身份"
          onClick={() => {
            props.onCreateNewUser();
          }}
        >
          <div
            slot="media"
            style={{
              height: 44,
              width: 44,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img
              src={addUserIcon}
              alt="新建患者"
              style={{ width: 30, height: 30 }}
            // color="#2979ff"
            ></img>
          </div>
        </ListItem>
      </List>
    );
  }
  return (
    <Page key={props.symptom && props.symptom.id}>
      <Navbar>
        <NavLeft>
          <Link popupClose>
            <Icon icon="icon-back"></Icon> <span> 返回</span>
          </Link>
        </NavLeft>
        <NavTitle>请选择用户</NavTitle>
        <NavRight >
          <Link  onClick={() => {
              props.onCreateNewUser();
            }}
          >
            <img
              src={titleAddUser}
              alt="新建患者"
              style={{ width: 30, height: 30 }}
            />
          </Link>
        </NavRight>
      </Navbar>
      <Block>
        {/* <BlockTitle>请选择您的身份，或者创建新身份</BlockTitle> */}
        <List mediaList inset>
          <div>
            <Button
              fill
              style={{ float: 'right', marginBottom: 10 }}
              color={deleteUserFlag ? 'blue' : 'red'}
              onClick={() => {
                setDeleteUserFlag(!deleteUserFlag)
              }}>{deleteUserFlag ? '完成' : '删除用户'}</Button>
          </div>
          <div style={{ clear: 'both' }}></div>
          {props.userList.map((userInfo, index) => {
            return (
              <ListItem
                id={'userID' + userInfo.id}
                key={'userID' + userInfo.id}
                title={userInfo.name || `用户-${++index}`}
                subtitle={(userInfo => {
                  let gender = '';
                  if (userInfo.gender === 'male') {
                    gender = '男';
                  }
                  if (userInfo.gender === 'female') {
                    gender = '女';
                  }
                  let age = '';
                  if (
                    userInfo.birthday !== undefined &&
                    userInfo.birthday !== null && 
                    userInfo.birthday
                  ) {
                    age = `${moment().diff(
                      moment(userInfo.birthday),
                      'year'
                    )}岁`;
                  }
                  return `${gender} ${age}`;
                })(userInfo)}
                onClick={() => {
                  if (event.target.getAttribute("class").indexOf('deleteUser') == -1 && !deleteUserFlag) {
                    props.onSelectUser(userInfo.id);
                    setCurrentUserInfo(userInfo);
                  }
                }}
              >
                {userInfo.avatar ? (
                  <img
                    slot="media"
                    src={userInfo.avatar}
                    width="44"
                    alt="头像"
                  />
                ) : (
                    <div
                      slot="media"
                      style={{
                        height: 44,
                        width: 44,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <img
                        alt="新建患者"
                        src={userIcon}
                        style={{ width: 40, height: 40 }}
                      // color="#2979ff"
                      ></img>
                    </div>
                  )}
                {
                  deleteUserFlag ? (
                    <Button
                      fill
                      color="red"
                      style={{ position: 'absolute', top: 20, right: 10 }}
                      className="deleteUser"
                      onClick={() => {
                        // if (confirm('是否删除该用户？')) {
                        //   document.getElementById('userID' + userInfo.id).style.display = 'none';
                        //   props.onDeleteUser(userInfo.id);
                        // }
                        window.$$f7.dialog.confirm('是否删除该用户？', '温馨提示', () => { 
                          document.getElementById('userID' + userInfo.id).style.display = 'none';
                          props.onDeleteUser(userInfo.id);
                        })
                      }}
                    >删除</Button>
                  ) : null
                }
              </ListItem>
            );
          })}
        </List>
        {/* {renderAddNewUser()} */}
        <BlockFooter>
          为了保护您的隐私、确保测评的准确性，请每一位测评对象选择属于自己的用户。如果您在上面的列表中没有找到自己，请选择「新建用户」。
        </BlockFooter>
      </Block>
    </Page>
  );
}
