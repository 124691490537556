const customConfig = {
  // 是否需要对卡号进行转换
  needConvert: false,
  // 登录方式
  loginType: {
    loginTypeTitle: '请选择证件类型并输入',
    options: [
      {
        label: '身份证号',
        value: 'patientIdCard',
        // 卡号长度
        cardLength: 10,
      },
      {
        label: '就诊卡号/住院号',
        value: 'patientCard',
        // 卡号长度
        cardLength: 10,
      }
    ]
  }
}
export default customConfig;