import React, { useState, useCallback, useEffect } from 'react';
import {
  Page,
  Button,
  Input
} from 'framework7-react';
import { getPatientList } from '../../modules/shufu';
import robot_smile from '../../images/robot_smile.png';
import shufu_logo from '../../images/shufu-logo.png';
import CONFIG from 'GlobalConfigFile';
import {pageOrgByEnv, uiConfigs} from './pageConfig';
import getParameterByName from '../../utils/getParameterByName';
/**
 * 根据身份证号查询PatientList，，之前山东是掉的单独接口，不通用
 * 此页面调用的接口增加参数organizationId
 * 以后使趋于使用此通用页面
 * ----env中id保持为organizationId
 * @param {*}
 * @returns 
 */
const LoginWithIdentityId = function (props) {
  const [ricnStr, setRicnStr] = useState(''); // 手动输入绑定的对应的value

  // 定制化界面logo，notes等，需要去pageConfig中查询
  const server = CONFIG.getRemoteConfig().server;
  // 环境和orgId之前默认一致，但是浦东精卫等有些不一样，单独配置了，所以使用配置，取不到默认和server保持一致
  const organizationId = CONFIG.getRemoteConfig().organizationId || server;
  const uiEnv = pageOrgByEnv[server] || 'default';
  // ui配置
  const uiConfig = uiConfigs[uiEnv];

  const _getPatientList = async (identityId) => {
    const mock = getParameterByName('mock');
    const params = {
      ricn: identityId,
      organizationId: organizationId,
      mock
    }
    let res = await getPatientList (params);
    if (res && res.length > 0) {
      props.f7router.navigate('/medicalRecordList', {
        props: {
          medicalRocordList: res,
          identityId: identityId
        }
      });
    } else {
      window.$$f7.dialog.alert('暂未查到您的挂号信息');
    }
  }

  const renderRicnInput = () => {
    const borderColor = (!ricnStr || isRicnValid()) ? "#2979ff" : "maroon";
    return (
      <div>
        <div style={{ fontSize: '18px', margin: '20px' }}>
          请输入您的身份证号
        </div>
        <div style={{ display: 'flex', margin: '0 20px', justifyContent: 'center', alignItems: 'center' }}>
          <div
            style={{
              flex: 1,
              height: 35,
              borderRadius: 10,
              border: `solid 1px ${borderColor}`,
              backgroundColor: '#deeaff',
              display: 'flex',
              alignItems: 'center'
            }}>
            <Input
              style={{ flex: '1', marginLeft: 10, marginRight: 10 }}
              type="text"
              value={ricnStr}
              onChange={event => {
                const input = event.target.value;
                setRicnStr(input);
              }}
              placeholder="请输入您的身份证号"
            />
            {(ricnStr && !isRicnValid()) ?
              <div
                style={{ fontSize: '16px', margin: '20px', color: "maroon" }}
              >
                身份证号有误
              </div> : null
            }
          </div>
        </div>
      </div>
    );
  }

  const isRicnValid = () => {
    if (!ricnStr) return false;
    const patternMainLand = new RegExp(/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/); // 大陆
    const patternHongkong = /^((\s?[A-Za-z])|([A-Za-z]{2}))\d{6}(\([0−9aA]\)|[0-9aA])$/; // 香港
    const patternTaiwan = /^[a-zA-Z][0-9]{9}$/; // 台湾
    const patternMacao = /^[1|5|7][0-9]{6}\([0-9Aa]\)/; // 澳门
    return patternMainLand.test(ricnStr)
      || patternHongkong.test(ricnStr)
      || patternTaiwan.test(ricnStr)
      || patternMacao.test(ricnStr);
  }

  const renderSubmitButton = () => {
    return <Button
      raised
      fill
      large
      style={{
        backgroundColor: isInputsValid() ? '#2979ff' : '#c2c2c2',
      }}
      className='welcome-login-button'
      onClick={() => {
        if (!isInputsValid()) return;
        _getPatientList(ricnStr);
      }}
    >确定
    </Button>
  }

  const isInputsValid = () => {
    if (!ricnStr) return false;
    if (!isRicnValid()) return false;
    return true;
  }

  return (
    <Page noToolbar noNavbar noSwipeback loginScreen className='welcome-container-body'>
      <img className='welcome-shufu-logo-ricn' src={uiConfig.logo}></img>
      <div className='welcome-words'>
        <span className='welcome-words-text'>{uiConfig.welcomeText}</span>
        <img className='welcome-words-image' src={robot_smile}></img>
      </div>
      {
        renderRicnInput()
      }
      {
        renderSubmitButton()
      }
      <div style={{ margin: 20, color: "crimson", fontSize: 15, fontWeight: "bold" }}>
        {uiConfig.notes}
      </div>
      <div style={{ fontSize: '14px', color: '#8c8c8c', position: 'fixed', textAlign: 'center', width: '100%', bottom: 40 }}>
        Powered by Siuvo.Inc
      </div>
    </Page>
  );
};
export default LoginWithIdentityId;
